<template>
  <div class="carbon-calulator">
    <public-header />

    <div class="site-content pt-2">
      <div class="bg-white py-2 mb-3">
        <div class="container">
          <h4>Carbon Calculator</h4>
        </div>
      </div>

      <carbon-calculator />
    </div>

    <public-footer />
  </div>
</template>

<script>
import PublicHeader from "@/components/site/PublicHeader.vue";
import PublicFooter from "@/components/site/PublicFooter.vue";

export default {
  components: {
    PublicHeader,
    PublicFooter,
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },
};
</script>
